// noinspection JSUnusedGlobalSymbols
export const environment = {
  production: false,
  version: '2.3.54.533',
  restApiUrl: 'https://api1047710-test.einfachmacher.de/api/v1',
  sitsApiKey: 'rLT2NLPUdjRFQqvQ',
  aes256CtrVector: 'k6qRXoA1bZglk',
  statisticServiceApiUrl: '',
  logStatistics: false,
  imprintUrl: 'https://www.siw-wangerooge.de/siw-de/Impressum-5977912',
  sessionKey: '',
  targetKey: '10477',
  mainlandLocationName: 'Harlesiel,DE',
  islandLocationName: 'Wangerooge,DE',
  enableServiceWorker: false,
  externalGepaeckInterface: true
};

